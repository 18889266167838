<template>
  <br>
  2024학년도 1학기 기말고사 3학년 생명과학II 정답
  <br>
  <br>
  <button @click="goToLink">
    리로스쿨로 이동해서 다운로드
  </button>
</template>

<script>
export default {
  methods: {
    goToLink() {
      window.location.href = "https://soongsil.riroschool.kr/board.php?club=index&action=view&db=1042&page=&cate=&sort=&uid=1902&inum=783&key=&key2=&s1=&s2=&s3=";
    }
  }
}
</script>

<style>
button {
  background-color: var(--p1);
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}
</style>
